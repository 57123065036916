body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Fira Sans', sans-serif!important;
  color: #070707!important;
  font-size: calc(60% + 0.8vmin)!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.side-container {
  position: fixed;
  right: 0;
  top: 0;
}

img.signup-hero {
  width: 100%;
  height: auto;
}

img.img-cover {
  object-fit: cover;
}

.w-90 {
  width: 90%;
  max-width: 90%;
}

.mt--1 {
  margin-top: -1rem;
}

a:hover {
  color: #5f0a4a;
}

.link-mute {
  color: #6c757d!important;
}

/*loading spinner*/
div.nextui-c-fpphdg {
    width: 20rem;
    height: 4rem;
}

div.loginForm {
  height: max-content;
  width: 100%;
  position: relative;
}

.hover-whatsapp:hover {
  color: #13A452!important;
}

.Toastify__toast-container {
  z-index: 10000!important;
}

@media (min-width: 992px) {
    img .signup-hero {
    width: 1500px;
    height: 1000px;
    object-fit: cover;
    object-position: 40% 0;
    margin-top: -18rem;
  }

  img.login-hero {
    width: 1500px;
    height: 800px;
    object-fit: cover;
    object-position: 57% 0;
    margin-top: -18rem;
  }

  .fields {
    padding-right: 3rem;
  }
}

@media (max-width: 990px) {
  .t-center {
    text-align: center !important;
    line-height: 0.8!important;
  }

  .text-width {
    width: 75%!important;
  }

  img.signup-hero {
    width: 100%;
    max-width: 100%;
    height:auto;
    margin-top: -5rem;
  }

  img.login-hero {
    width: 100%;
    max-width: 100%;
    height:auto;
    margin-top: -5rem;
  }

  .signup-container {
    width: 100%!important;
    max-width: 100%;
  }

  
  .marg-auto {
    margin-left: auto!important;
    margin-right: auto!important;
  }

  .no-pad {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
  
  .nextui-c-grJsex-iivoRvx-css {
    max-width: 75%!important;
  }
}

@media (max-width: 500px) {
  img.signup-hero {
    display: none;
  }

  img.login-hero {
    display: none;
  }

  .text-width {
    width: 90%!important;
  }

  .dis-none {
    display: none;
  }

  .t-center {
    text-align: center !important;
  }

  .center {
    justify-content: center!important;
  }

  .home-hero {
    display: flex;
    flex-direction: column-reverse;
  }
  
  .nextui-c-grJsex-iivoRvx-css{
    max-width: 95%!important;
  }

}

@media (min-width:600px) {
  .size-2 {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}

@media (min-width:1000px) {
  .size-2 {
    font-size: 2rem;
    margin-top: 3.5rem;
  }
}

@media (min-width:1200px) {
  .size-2 {
    font-size: 2.8rem;
    margin-top: 4.5rem;
  }

  .size-1 {
    font-size: 2rem;
  }
}

@media (max-width: 800px) {
  .hero-but {
    min-width: 10rem!important;
    height: 2.5rem!important;
  }
}

@media (max-width: 575px) {
  .footer-logo {
    display: flex!important;
    justify-content: center!important;
  }
}

@media (max-width: 600px) {
  .home-text-hero {
    text-align: center;
  }

  .size-2 {
    font-size: 1.5rem;
  }

  .size-1 {
    font-size: 1rem;
  }

  /*loading spinner*/
    div.nextui-c-fpphdg {
        width: 10rem;
        height: 3rem;
    }

  .side-container {
    display: none;
  }

}